<template>
  <div class="">
    <el-dialog
      title="修改手机号"
      :visible.sync="dialogVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
        >
          <el-form-item label="当前手机号">
            <el-input
              disabled
              v-model="nowPhoneNum"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="更改手机号"
            prop="mobile"
          >
            <el-input
              v-model="form.mobile"
              maxlength="11"
            >
              <el-button
                style="width:120px;"
                slot="append"
                :disabled="disableCodeBtn"
                @click="getPhoneCode"
              >{{codeBtnText}}</el-button>
            </el-input>
          </el-form-item>
          <el-form-item
            label="验证码"
            prop="sms_code"
          >
            <el-input v-model="form.sms_code">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="editPhone"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSmsCodeApi } from '../api/common'
import { editPhoneNumApi } from '../api/user'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var pattern = /^(1[3|4|5|7|8|9][0-9])\d{8}$/
        // this.disabledBtn = !pattern.test(this.mobile)
        if (pattern.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确手机号'))
        }
      }
    }
    return {
      // timer: null,
      disableCodeBtn: false,
      codeBtnText: '获取验证码',
      dialogVisible: false,
      form: {
        mobile: '',
        sms_code: ''
      },
      rules: {
        mobile: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        sms_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  // 计算属性
  computed: {
    nowPhoneNum () {
      if (this.$store.state.userInfo) {
        return this.$store.state.userInfo.base.user_mobile
      } else {
        return ''
      }
    }
  },
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {
    editPhone () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        const res = await editPhoneNumApi(this.form)
        if (res && res.code === 0) {
          this.$message.success('修改成功')
          const user = this.$store.state.userInfo
          user.base.user_mobile = this.form.mobile
          this.$store.commit('setUserInfo', user)
          this.dialogVisible = false
        }
      })
    },
    getPhoneCode () {
      this.$refs.form.validateField('mobile', async (valid) => {
        console.log(valid)
        if (valid) return
        this.disableCodeBtn = true
        const res = await getSmsCodeApi({
          mobile: this.form.mobile,
          type: 3
        })
        if (res && res.code === 0) {
          // console.log(123)
          this.codeBtnText = 60
          const timer = setInterval(() => {
            console.log(this.codeBtnText)
            this.codeBtnText = this.codeBtnText - 1
            if (+this.codeBtnText === 0) {
              clearInterval(timer)
              this.codeBtnText = '获取验证码'
              this.disableCodeBtn = false
            }
          }, 1000)
        } else {
          this.disableCodeBtn = false
        }
      })
    },
    openDialog () {
      this.dialogVisible = true
    },
    closeDialog () {
      console.log(this.$refs.form)
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog {
  min-width: 500px !important;
}
</style>
